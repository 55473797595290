<template>
    <div class="xyc-search-plug">
        <el-input class="search-input"  v-click-outside="handleBlur"
        placeholder="输入企业名称" 
        @input="changeCompanyName"  
        @focus="onFocus"
        v-model="companyName"></el-input>
        <div class="search-content" v-if="isShow">
            <div class="flex ai-c"
            v-for="(item,index) in companyList" :key="index"
            :class="'company'+index" 
            @click="goSearchContent(item.name)">
              <div class="search-icon">{{item.sx}}</div>
              <div v-html="item.html"></div>
            </div>
          </div>
    </div>
</template>
  
<script>
  import { computed, ref,toRefs, onMounted, unref, reactive, nextTick } from 'vue';
  import { getCompanyList } from '@/api/search'
  import router from '@/router'
  import { getSx } from '@/utils/index' 
  const clickOutside = {
    bind(el, binding) {
      // 在元素上绑定一个点击事件监听器
      el.clickOutsideEvent = function (event) {
        // 检查点击事件是否发生在元素的内部
        if (!(el === event.target || el.contains(event.target))) {
          // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
          binding.value(event);
        }
      };
      // 在文档上添加点击事件监听器
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
      // 在元素上解除点击事件监听器
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  }; 
export default {
    directives: {
      "click-outside": clickOutside, // 注册自定义指令
    },
    name: 'searchInput',
    props: ['userToken'],
    components:{},
    setup(props,context) {
        const state = reactive({
            companyName: '',
            isShow: false,
            companyList: [],
        });
        const changeCompanyName = (val) => { 
            debounce()
        } 
        const setHightLight = (arr, keyword)=> {
          if (arr && arr.length > 0 && keyword) {
            let newArr = [];
            arr.map((item, index) => {
              let obj = {
                name: item,
                html: null,
                sx: getSx(item)
              };
              let reg = new RegExp(keyword, "g");
              let replaceString = `<span class='searHigh'>${keyword.trim()}</span>`;
              if (obj.name.includes(keyword)) {
                obj.html= item.replace(reg, replaceString);
                newArr.push(obj);
              }else{
                obj.html = item;
                newArr.push(obj);
              }
            });
            return newArr;
          }
           // 空返回原数组
          if(!keyword){
            return []
          } 
        }
        const getList = async  () => {
          if(!state.companyName.trim()) return;
          console.log('comany名称', state.companyName)
          let number = 5;
          if(props.userToken){
            number = 10; 
          }   
          await getCompanyList({
              keyWorld: state.companyName.trim(), 
              pageSize: number,
              pageNum: 1,
          }).then(res => { 
              if(res.companyName){
                state.isShow = true;
                state.companyList = setHightLight(res.companyName || [], state.companyName) ;
              } 
            }).catch(error => {
            
            })
        }
    
        let timer = null;
        const debounce = (delay=500)=> {
          if(timer){
            clearTimeout(timer) //清空定时器
          }
          timer = setTimeout(function () { 
          getList()
          }, delay) //延迟一定时间执行 
        }
        const onFocus = () => { 
          if(state.companyName.trim() && state.companyList.length){
            state.isShow = true;
          }
        }
      
        const goSearchContent = (item) => {
          if(props.userToken){
              router.push({ path: "/company/baseinfo", query: {companyname: item} }); 
          }else{
            //去登录
            context.emit('goLogin')
          }
          state.isShow = false;
        } 
     
        const handleBlur =(event)  => {
          state.isShow = false;
        }
        return {
            ...toRefs(state),
            changeCompanyName,
            goSearchContent,
            onFocus,
            handleBlur
        };  
    }, 
  }
   
  </script>
  
  
  <style lang="scss">
  
   .search-input{
      width: 323px;//578
      height: 36px;//56
      font-size: 14px;//8
      font-weight: 500;
    }
    .xyc-search-plug{
        position: relative;
    }
    .search-content{
      position: absolute;
      left: 0;
      top: 42px;
      width: 415px;
      height: 292px;
      overflow-y:auto;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px #AAAAAA;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 20px;
     
     
      .company5 .search-icon,.company0 .search-icon{ background: #C8B7D8; }
      .company6 .search-icon, .company1 .search-icon{ background: #A0C8B0; }
      .company7 .search-icon, .company2 .search-icon{ background: #D8B7B7; }
      .company8 .search-icon, .company3 .search-icon{ background: #D8CEB7; }
      .company9 .search-icon, .company4 .search-icon{ background: #B7C7D8; }
      .flex:last-child{margin-bottom: 0;}
      .flex{
        margin-bottom: 8px;cursor: pointer;
        &:hover{ 
          color: #006CFF;
          .searHigh{
            color:#006CFF;
          }
        }
      }
      .searHigh{
        color:#F44F4F;
      }
    }
    .search-icon{
      width: 48px;
      height: 48px; 
      border-radius: 8px;
      font-size: 12px;
      padding: 6px 10px;
      color: #fff;
      margin-right:15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  </style>
  