<template>
  <div class="xyc-footer">
    <div class="xyc-footer-container flex fd-c ai-c">


    <div class="content flex  jc-sb" >
      <div class="logo flex fd-c ai-c">
        <img src="../../assets/home/logo2.png" alt="">
        <span class="text flex jc-c ai-c">专业的企业AI尽调工具</span>
      </div>
      <div class="flex fd-c ai-c">
        <div class="title">关于我们</div>
        <router-link to="/userAgreement">用户协议</router-link>
        <router-link to="/privacyAgreement" style="margin: 16px 0;">隐私协议</router-link>
        <router-link to="/vipAgreement">会员服务协议</router-link>
      </div>
      <div>
        <div class="title">联系我们</div>
        <div style="margin: 16px 0;">服务时间: 周一至周五9:00-18:00</div>
        <div style="width: 278px;">公司地址: 武汉东湖高新技术开发区关东街道大学园路15号附1号华中科技大学科技园现代服务示范基地二期4#楼8层804房</div>
      </div>
      <div class="flex ">
        <div style="margin-right: 60px;">
          <div class="title">微信公众号</div>
          <div>
            <img src="@/assets/home/xyc.jpg" style="width: 100px;height: 100px;" alt="">
          </div>
        </div>
        <div>
          <div class="title">咨询客服</div>
          <div >
            <img src="@/assets/home/kf.jpg" style="width: 100px;height: 100px;" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="footer-line"></div>
    <div class="bah">
      <div class="flex jc-c">
        数据来源：全国企业信用信息公示系统&nbsp;中国裁判文书网&nbsp;中国执行信息公开网&nbsp;国家知识产权局&nbsp;国标局&nbsp;版权局
      </div>
      <div class="flex jc-c">
        Copyright@2019-2022 Company name All rights reserved. <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备2022016544号-3</a>
      </div>

    </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted,toRefs, unref, reactive } from 'vue';

import router from '@/router'
export default {
  name: 'home',
  components:{},
  setup() {
    const state = reactive({

      });
      // 获取当前的路由路径

      // console.log(router)

    return {
        ...toRefs(state),
      };
  }
}

</script>


<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
  .xyc-footer{
      background: #282E38;
      font-size: 14px;
      padding-top: 50px;
      min-width: $pageWidth;
      .content{
        color: #777;
        width: 100%;
      }
      .text{
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 16px
      }
      .title{
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 24px;
      }
      .logo .text{
        width: 168px;
        height: 48px;
        background: #39414D;
        border-radius: 4px;
      }
      .footer-line{
        width: 100%;
        height: 1px;
        background: rgba(169,169,169,0.4);
        margin: 24px 0;
      }
      .bah{
        font-weight: 500;
        color: #777777;
        margin-bottom: 16px;
        >div{
          margin-bottom: 8px;
        }
      }
  }

</style>
