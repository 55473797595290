<template>
   <div class="time-count flex" v-if="unNewUser"> 
      <div>{{day}}</div>
      <div class="txt">天</div>  
      <div>{{hour}}</div> 
      <div class="txt">时</div> 
      <div>{{min}}</div>
      <div class="txt">分</div>  
      <div>{{sen}}</div>
      <div class="txt">秒</div> 
    </div>	
</template>
  
<script>
    import { computed, ref,toRefs, onMounted, unref, reactive, nextTick } from 'vue';
     
export default {
    name: 'timeCount', 
    components:{},
    props:['endTime'],
    setup(props) {
      const state = reactive({
        unNewUser: false,
        day: '2',
				hour: '21',
				min: '32',
				sen: '21',
      });
      const showTime = (end, timer) => {
				let endTime = new Date(end).getTime();
				let newTime = new Date().getTime();
				let diffTime = (endTime - newTime) / 1000;
				if(diffTime == 0 || diffTime < 0){
					clearInterval(timer);
					state.unNewUser = false;
				}else{
					state.unNewUser = true;
				}
				let day = parseInt(diffTime / 60 / 60 / 24);
				let hour = parseInt(diffTime / 60 / 60 % 24);
				let min = parseInt(diffTime / 60 % 60);
				let sen = parseInt(diffTime % 60);
				state.day = setNumber(day);
				state.hour = setNumber(hour);
				state.min = setNumber(min);
				state.sen = setNumber(sen); 
				// console.log(day, hour, min , sen)
			};
      const setNumber = (number) => {
				if(number<10){
					return '0'+number;
				}else{
					return number;
				}
			};
      // if(res.data.data&& res.data.data.memberEndTimeStr){
        const timer = setInterval(()=>{
          showTime(props.endTime, timer)
        },1000);
      // }
      return {
        ...toRefs(state),
        showTime,
        setNumber,
      };  
    }
  }
   
  </script>
  
  
  <style scoped lang="scss">
  .flex{
    display: flex;
    align-items: center;
  }
    .time-count{
      position: absolute;
      right: 0;
      top: 0;
      min-width: 92px;
      height: 20px;
      background: linear-gradient(90deg, #FF4040 0%, #FF8D1A 100%);
      border-radius: 0px 10px 0px 10px;
      padding: 4px 10px;
      
      font-size: 12px; 
      font-weight: 500;
      color: #FED500;
      .txt{color: #fff;}
    }
       
  </style>
  