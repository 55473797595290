import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from "@/layout";
import LayoutPage from "@/layout/page.vue";

export const constantRoutes = [
  {
    path: "/redirect/:path(.*)",
    component: () => import("@/views/redirect"),
  },
  {
    path: "/",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/privacyAgreement",
    component: () => import("@/views/agreement/privacyAgreement"),
    hidden: true,
  },
  {
    path: "/userAgreement",
    component: () => import("@/views/agreement/userAgreement"),
    hidden: true,
  },
  {
    path: "/vipAgreement",
    component: () => import("@/views/agreement/vipAgreement"),
    hidden: true,
  },
  {
    path: "/xyc",
    component: Layout,
    redirect: "center",
    children: [
      {
        path: "center",
        component: () => import("@/views/user/userinfo"),
        name: "userCenter",
        meta: { title: "账号信息", icon: "user", affix: true },
      },
    ],
  },
  {
    path: "/commonTools",
    component: Layout,
    redirect: "myservices",
    meta: { title: "常用工具", icon: "user", affix: true },
    children: [
      {
        path: "myservices",
        component: () => import("@/views/commonTools/myServices/index"),
        name: "myservices",
        meta: { title: "我的服务", icon: "user", affix: true },
      },
      // {
      //   path: "downloadRecords",
      //   component: () => import("@/views/commonTools/myServices/index"),
      //   name: "downloadRecords",
      //   meta: { title: "下载记录", icon: "user", affix: true },
      // },
      // {
      //   path: "myorders",
      //   component: () => import("@/views/commonTools/myServices/index"),
      //   name: "myorders",
      //   meta: { title: "我的订单", icon: "user", affix: true },
      // },
    ],
  },
  {
    path: '/company',
    component: LayoutPage,
    hidden: true,
    redirect: 'detail',
    children: [
      {
        path: 'detail',
        component: () => import('@/views/companyCollection/companyList'),
        name: 'companyList',
        meta: { title: '', icon: '',  }
      },
      {
        path: 'baseinfo',
        component: () => import('@/views/companyCollection/companyBaseInfo'),
        name: 'companyListBaseInfo',
        meta: { title: '', icon: '',  }
      },
      {
        path: 'companyFqz',
        component: () => import('@/views/companyCollection/companyFqz'),
        name: 'companyListFqz',
        meta: { title: '', icon: '',  }
      },
      {
        path: 'companyEmptyFqz',
        component: () => import('@/views/companyCollection/companyEmptyFqz'),
        name: 'companyEmptyFqz',
        meta: { title: '', icon: '',  }
      },
    ]
  },
];

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
