import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";

NProgress.configure({ showSpinner: false });
//whiteList
// const xycwhiteList = ['/login', '/auth-redirect', '/bind', '/register',]
const xycwhiteList = [
  "/",
  "/auth-redirect",
  "/bind",
  "/privacyAgreement",
  "/userAgreement",
  "/vipAgreement",
];
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken()) {
    to.meta.title && store.dispatch("settings/setTitle", to.meta.title);
    /* has token*/
    // console.log(' console.log(to.path)',to.path)

      // 判断当前用户是否已拉取完user_info信息
      store
        .dispatch("GetInfo")
        .then(() => {
          next()
        })
        .catch((err) => {
          store.dispatch("LogOut").then(() => {
            Message.error(err);
            // next({ path: '/' })
          });
        });

  } else {
    // 没有token
    // console.log(' 白名单',to.path)
    if (xycwhiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // /xychome?redirect   /login
      next(`/?redirect=${to.fullPath}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
