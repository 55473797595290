<template>
  <div class="login-block">
    <div class="title">{{ isUpdate?'修改密码': '找回密码'}}</div>
    <el-form ref="resetRef" :model="resetForm" :rules="resetRules">
      <el-form-item prop="username" label="手机号">
        <el-input
          v-model="resetForm.username"
          type="text"
          auto-complete="off"
          placeholder="请输入手机号"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="code" label="验证码">
        <el-input
          v-model="resetForm.code"
          auto-complete="off"
          placeholder="请输入验证码"
          @keyup.enter.native="handleLogin"
        > 
        <el-button slot="suffix"  :class="{'get-code':true, 'disable': totalSeconds<60&& totalSeconds>0}" @click="getCode" type="text">
          {{ totalSeconds<60&& totalSeconds>0 ? totalSeconds+'s' : '获取验证码' }}
        </el-button> 
      </el-input>
         
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input
          v-model="resetForm.password"
          type="password"
          auto-complete="off"
          placeholder="请输入登录密码"
        > 
        </el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword" label="确认密码">
        <el-input
          v-model="resetForm.confirmPassword"
          type="password"
          auto-complete="off"
          placeholder="请再次输入密码"
          @keyup.enter.native="handlereset"
        > 
        </el-input>
      </el-form-item>
     
      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          class="loginBtn"
          @click.native.prevent="handlereset"
        >
          <span>提交</span> 
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { computed, ref, onMounted,toRefs, unref, reactive, watch, onUnmounted } from 'vue';
import { resetPw, getPhoneCode } from '@/api/login'
import modal from '@/plugins/modal'
import store from '@/store'
import { MessageBox } from 'element-ui'
export default {
  name: 'home', 
  props: ['isAgree', 'update'],
  components:{},
  setup(props, context) {
    const validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号！'))
      } else {
        let reg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
        if (reg.test(value)) {
          callback()
        }else{
          callback(new Error('请输入正确的手机号！'))
        } 
      }
    }
    const validatePw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码！'))
      } else {
        console.log()
        if ( value == state.resetForm.password) {
          callback()
        }else{
          callback(new Error('请确认密码是否一致！'))
        } 
      }
    }
    const resetRef = ref(null)
    const state = reactive({
      loading: false,
      resetForm: {
        username: '',
        password: '',
        confirmPassword: '',
        code: ''
      },
      resetRules: {
        username: [
          { required: true, trigger: "blur",validator: validateUser, }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        confirmPassword: [
          { required: true, trigger: "blur", validator: validatePw }
        ],
        code: [
          { required: true, trigger: "blur", message: "请输入验证码" }
        ], 
      },
      totalSeconds: 0,
      interval: null,
    });
    const isUpdate = computed(()=>{
      return props.update;
    })
    const getTime = () => {
      state.totalSeconds = 60;
      state.totalSeconds -= 1; 
      state.interval = setInterval(function() {
        state.totalSeconds -= 1;  
        if (state.totalSeconds <= 0) {
        clearInterval(state.interval);
        }
      }, 1000);
    }
    const getCode = () => {
      if(state.totalSeconds > 0 && state.totalSeconds != 60){ 
        return;
      }
      unref(resetRef).validateField(['username'],(valid) => {
        console.log(valid)
        if (!valid) {
          console.log(valid)
          getTime();
          getPhoneCode({
            username: state.resetForm.username,
            codeType: '2'
          }).then(res => {  
            // modal.msgSuccess('获取验证码成功！')
          }).catch(error => { 
            modal.msgError('获取验证码失败！')
          })
        }
      })
    }
    const handlereset = () => {
      unref(resetRef).validate((valid) => {
        if (valid) {
          if(!props.isAgree){
            modal.msgError('您还未阅读协议！')
            return;
          }
          state.loading = true;
          let param = {
            ...state.resetForm
          }
          resetPw(param).then(res => {
            state.loading = false;
            if(isUpdate){
              context.emit('changeDialog')
              MessageBox.confirm('您已修改密码，是否退出登录？', "提示", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: "warning",
              }).then(()=>{
                store.dispatch('LogOut')
              }).catch(()=>{
                console.log('取消退出登录')
              })
              return;
            }
            context.emit('changeProcess', 'login')
            modal.msgSuccess('密码已找回，请去登陆！')

          }).catch(error => {
            state.loading = false;
          })
        } else {
          return false
        }
      })
    }
    onUnmounted(()=>{
      clearInterval(state.interval);
    })
    return { 
        ...toRefs(state),
        handlereset,
        resetRef,
        getCode,
        isUpdate,
      };
  }
}
 
</script>


<style scoped lang="scss">
  .login-block{  
    .flex{display: flex;}
    flex-direction: column;
    align-items: center;
    padding: 0 22px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    .loginBtn{
      width:100%;height: 43px;font-size: 16px;font-weight: 500;
    }
    .title{
      font-size: 18px; 
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 5px;
    }
    .el-tabs{
      width: 100%;
      ::v-deep .el-tabs__item{font-size: 16px;}
      ::v-deep .el-tabs__item:hover,.el-tabs__item.is-active{
        font-weight: 500;
      }
      ::v-deep .el-tabs__nav-wrap::after{background-color: transparent;}
    }
    ::v-deep .el-input__inner{
      height: 43px;
    }
    .el-form-item{margin-bottom: 18px;}
    .xyc-reset{
      margin: 10px 0 8px 0;
      justify-content: space-between;
      .flex{align-items: center;}
    }
    .blue{
      font-weight: 500;
      color: #1890ff;
      cursor: pointer;
    }
    .get-code{ 
      font-size: 16px;
      border-left: 1px solid #dedede;
      padding: 0 0 0 10px;
      border-radius: 0;
      width: 92px;
      box-sizing: border-box;
    }
    .get-code.disable{cursor: default;}
    ::v-deep .el-input__suffix{
        display: flex;
      align-items: center;
    }
  }

</style>
