<template>
  <el-dialog 
    custom-class="xyc-dialog-login"
    title=""
    :top="'30%'"
    :visible.sync="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :width="'384px'">
    <!--  -->
    <!-- :before-close="handleCloseDialog" -->
    <div class="xyc-login-head flex">
      <div>
        <div class="xyc-back" v-if="processStatus == 'register'" @click="changeProcess('login')">
          <img src="../../assets/home/back.png" class="imgW" alt="">
          已有账号？返回登录
        </div>
        <div class="xyc-back" v-if="processStatus == 'resetpw'" @click="changeProcess('login')">
          <img src="../../assets/home/back.png" class="imgW" alt="">
          返回登录
        </div>
      </div>
      <div @click="handleCloseDialog" style="cursor: pointer;"><img src="../../assets/home/close.png" class="imgW" alt=""></div>
    </div>
    <com-login v-if="processStatus == 'login'" @changeProcess="changeProcess" :isAgree="isAgree" @handleCloseDialog="handleCloseDialog"></com-login>
    <com-register v-if="processStatus == 'register'" @changeProcess="changeProcess" :isAgree="isAgree"></com-register>
    <reset-password v-if="processStatus == 'resetpw'" @changeProcess="changeProcess" :isAgree="isAgree"></reset-password>
   
    <div class="xyc-login-footer flex">
      <el-radio :label="true"  v-model="isAgree" @click.native.prevent="changeAgree(isAgree)">阅读并同意 
        <!-- <span>《小禹查用户协议》</span> -->
        <router-link class="link-type" :to="'/userAgreement'">《小禹查用户协议》</router-link>
        和
        <!-- <span>《小禹查隐私政策》</span> -->
        <router-link class="link-type" :to="'/privacyAgreement'">《小禹查隐私政策》</router-link>
      </el-radio>
    </div>
  
  </el-dialog> 
</template>

<script>
import { computed, ref, onMounted,toRefs, unref, reactive, watch } from 'vue';
import comLogin from "./component/login.vue" 
import comRegister from "./component/register.vue" 
import resetPassword from "./component/resetPassword.vue" 
export default {
  name: 'home', 
  props: [''],
  components:{comLogin, comRegister, resetPassword},
  setup(props, context) {
   
    const state = reactive({
      visible: true,
      isAgree: true,
      processStatus: 'login'
    });
    // watch(()=>props.dialogVisible, ()=>{
    //   state.visible = props.dialogVisible;
    // })
  
    const handleCloseDialog = (info) =>{
      context.emit('changeModal',info)
    }
    const changeProcess = (txt) => {console.log(state.isAgree)
      state.processStatus = txt;
    }
    const changeAgree = (val) => {
      state.isAgree = val == true ? false: true;
    }
    // defineExpose({  //加入以后抛出showChildren，父组件可以获取
    //   changeProcess
    // })
    return { 
        ...toRefs(state),
        handleCloseDialog,
        changeProcess,
        changeAgree,
      };
  }
}
 
</script>


<style scoped lang="scss">
  ::v-deep .el-dialog__body{
    padding: 20px 0;
  }
.xyc-dialog-login{
 
  .flex{display: flex;}
  .xyc-login-head{
    justify-content: space-between;
    padding: 0 22px 10px 22px;
    .xyc-back{
      font-size: 14px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #006CFF;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .xyc-login-footer{
    justify-content: center;
    margin-top: 16px;
    ::v-deep .el-radio__input.is-checked + .el-radio__label{
      color: #606266;
    }
    ::v-deep .el-radio__label{padding-left: 2px;}
  }
  .imgW{width: 16px;height: 16px;}
}
</style>
