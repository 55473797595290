<template>
  <div
    :class="{ 'has-logo': showLogo }"
    style="backGround:#ffffff"
  >
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :unique-opened="true"
        :active-text-color="settings.theme"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in sidebarRouters"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      sidebarRouters: [
        {
          name: "Xyc",
          path: "/xyc/center",
          hidden: false,
          component: "Layout",
          meta: {
            title: "账号信息",
            icon: "rate",
            noCache: false,
            link: null,
          },
        },
        {
          name: "Xyc",
          path: "/xyc/myservices",
          hidden: false,
          component: "Layout",
          meta: {
            title: "常用工具",
            icon: "rate",
            noCache: false,
            link: null,
          },
          children: [
            {
              name: "Xyc",
              path: "/commonTools/myservices",
              hidden: false,
              component: "Layout",
              meta: {
                title: "我的服务",
                icon: "rate",
                noCache: false,
                link: null,
              },
            },
            // {
            //   name: "Xyc",
            //   path: "/commonTools/downloadRecords",
            //   hidden: false,
            //   component: "Layout",
            //   meta: {
            //     title: "下载记录",
            //     icon: "rate",
            //     noCache: false,
            //     link: null,
            //   },
            // },
            // {
            //   name: "Xyc",
            //   path: "/commonTools/myorders",
            //   hidden: false,
            //   component: "Layout",
            //   meta: {
            //     title: "我的订单",
            //     icon: "rate",
            //     noCache: false,
            //     link: null,
            //   },
            // },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return false; //this.$store.state.settings.sidebarLogo;
    },
    isCollapse() {
      return false; //!this.sidebar.opened;
    },
  },
};
</script>
