<template>
    <div class="apply-info">
        <el-dropdown>
            <div class="apply-btn">
                <img src="../../../assets/home/apply.png" alt="">
                <span>应用</span>
                <img src="../../../assets/home/down.png" alt="">
            </div>
            <el-dropdown-menu slot="dropdown" style="border-radius: 12px; padding: none;">
              <div class="user-detail-block">
                <div class="tool-title"><div class="line"></div> 热门应用</div>
                <div class="user-tool-block">
                  <div v-for="item in hotData" :key="item.id" class="tool-item">  
                     <img :src="item.icon" alt=""> 
                     <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
              <div class="user-detail-block">
                <div class="tool-title"><div class="line"></div>AI反欺诈</div>
                <div class="user-tool-block" style="width: 79%;">
                  <div v-for="item in fqzData" :key="item.id" class="tool-item" @click="goApply(item.url)">  
                     <img :src="item.icon" alt=""> 
                     <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
    </div>
</template>
  
<script>
    import { computed, ref,toRefs, onMounted, unref, reactive, nextTick } from 'vue';
    import apply1 from "@/assets/home/apply1.png"
    import apply2 from "@/assets/home/apply2.png"
    import apply3 from "@/assets/home/apply3.png"
    import apply4 from "@/assets/home/apply4.png"
    import apply5 from "@/assets/home/apply5.png"
    import router from '@/router'
export default {
    name: 'apply', 
    props: ['userToken'],
    components:{},
    setup(props,context) {
      const state = reactive({
        hotData:[
            {title: 'AI经营报告',icon: apply1, id: 1,url: ''},
            {title: 'AI供应商查验',icon: apply2, id: 2,url: ''},
            {title: '风险监控',icon: apply3, id: 3,url: ''}, 
        ],
        fqzData:[ 
            {title: '法人认证',icon: apply4, id: 4,url: '/company/companyFqz'},
            {title: '空壳扫描',icon: apply5, id: 5,url: '/company/companyEmptyFqz'},
        ], 
      });
      const goApply = (url) => {
        if(props.userToken){
            router.push({ 
              path: url,
              query: {
                companyname: '',
                comdetail: '',
              } 
            }); 
          }else{
            //去登录
            context.emit('goLogin')
          }
      }
      return {
        ...toRefs(state),
        goApply,
      };  
    }
  }
   
  </script>
  
  
  <style scoped lang="scss">
    .apply-info{
        .apply-btn{
            width: 80px;
            height: 34px;
            background: #E8F1FF;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:0 8px;
            cursor: pointer;
        }
    }
    
 .tool-title{
    font-size: 14px;margin-bottom: 20px;
    display: flex;
    align-items: center;
    .line{
        margin-right: 6px;
        width: 5px;
        height: 22px;
        border-radius: 20px;
        background: linear-gradient(135deg, #00A1FC 0%, #006CFF 100%);
    }
}
 .user-detail-block{
    
    width: 324px;
    // height: 274px;
    background: #FFFFFF;
    padding:14px 20px;
    // box-shadow: 0px 0px 4px 0px #AAAAAA;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-size: 14px;
    color: #333;
    .user-info-detail{
      display: flex;
      .info-detail{
        margin-left: 10px;
        display: flex;
        flex-direction: column;
      }
      .name{
        font-size: 16px;
        // font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
      .phone{
        font-size: 12px;
        // font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
      }
    }
    .user-tool-block{
      // font-size: 14px;
      display: flex;
      justify-content: space-around;
      
    }
    .tool-item{
      display: flex;
      flex-direction: column;
      img{width: 44px;height: 44px;margin-bottom: 10px;}
      cursor: pointer;
    }
   
  }
  </style>
  