<template>
 <div class="login-block flex">
      <img src="../../../assets/home/login.png" style="width:144px;height: 49px;margin-bottom: 25px;" alt="">
      <el-tabs v-model="activeTab">
        <!-- <el-tab-pane label="用户管理" name="first">扫描登录</el-tab-pane> -->
        <!-- <el-tab-pane label="密码登录" name="0">
          <el-form ref="loginRef" :model="loginForm" :rules="loginRules" class="login-form">
            <el-form-item prop="username" label="手机号">
              <el-input
                v-model="loginForm.username"
                type="text"
                auto-complete="off"
                placeholder="账请输入手机号"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
              <el-input
                v-model="loginForm.password"
                type="password"
                auto-complete="off"
                placeholder="请输入密码"

              >
              </el-input>
            </el-form-item>
            <div class="xyc-register flex">
              <div>
                还不是会员？<span class="blue" @click="changeProcess('register')">立即注册</span>
              </div>
              <div class="flex blue" @click="changeProcess('resetpw')">
                <img src="../../../assets/home/why.png" style="width:16px;height: 16px;" alt="">
                忘记密码
              </div>
            </div>

          </el-form>
        </el-tab-pane> -->
        <el-tab-pane label="验证码登录" name="1">
          <el-form ref="loginCodeRef" :model="loginCodeForm" :rules="loginCodeRules" class="login-form">
            <el-form-item prop="userPhone" label="手机号">
              <el-input
                v-model="loginCodeForm.userPhone"
                type="text"
                auto-complete="off"
                placeholder="账请输入手机号"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="messageCode" label="验证码">
              <el-input
                v-model="loginCodeForm.messageCode"
                auto-complete="off"
                placeholder="请输入验证码"

              >
              <!--   @keyup.enter.native="handleLogin" -->
              <el-button slot="suffix"  :class="{'get-code':true, 'disable': totalSeconds<60&& totalSeconds>0}" @click="getCode" type="text">
                {{ totalSeconds<60&& totalSeconds>0 ? totalSeconds+'s' : '获取验证码' }}
              </el-button>

              </el-input>

            </el-form-item>
            <div class="xyc-register flex" style="color:#999;justify-content: center;">
              若没有账号，会自动注册新账号
            </div>

          </el-form>
        </el-tab-pane>
       </el-tabs>
       <el-button
          :loading="loading"
          size="medium"
          type="primary"
          class="loginBtn"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">立即登录</span>
          <span v-else>登 录 中...</span>
        </el-button>
    </div>
</template>

<script>
import { computed, ref, onMounted,toRefs, unref, reactive, watch, onUnmounted } from 'vue';
import store from '@/store/index.js'
import modal from '@/plugins/modal'
import { setRegister, getPhoneCode } from '@/api/login'
import router from '@/router'
import { isRelogin } from '@/utils/request'
export default {
  name: 'home',
  props: ['isAgree'],
  components:{},
  setup(props, context) {
    const loginRef = ref(null);
    const loginCodeRef = ref(null);
    const validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号！'))
      } else {
        let reg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
        if (reg.test(value)) {
          callback()
        }else{
          callback(new Error('请输入正确的手机号！'))
        }
        callback()
      }
    }
    const state = reactive({
      redirect: '',
      activeTab: '1',
      loading: false,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        userPhone: [
          { required: true, trigger: "blur",validator: validateUser,  }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
      },
      loginCodeForm: {
        userPhone: '',
        messageCode: '',
      },
      loginCodeRules: {
        userPhone: [
          { required: true, trigger: "blur",validator: validateUser,  }
        ],
        messageCode: [
          { required: true, trigger: "blur", message: "请输入验证码" }
        ],
      },
      interval: null,
      totalSeconds: 0,
    });

    const getTime = () => {
      state.totalSeconds = 60;
      state.totalSeconds -= 1;
      state.interval = setInterval(function() {
        state.totalSeconds -= 1;
        if (state.totalSeconds <= 0) {
        clearInterval(state.interval);
        }
      }, 1000);
    }
    const getCode = () => {
      if(state.totalSeconds > 0 && state.totalSeconds != 60){
        return;
      }
      unref(loginCodeRef).validateField(['userPhone'],(valid) => {
        console.log(valid, !valid)
        if (!valid) {
          getTime();
          getPhoneCode({
            userPhone: state.loginCodeForm.userPhone,
            codeType: '0'
          }).then(res => {
            // modal.msgSuccess('获取验证码成功！')
          }).catch(error => {
            modal.msgError('获取验证码失败！')
          })
        }
      })
    }
    const handleLogin = () => {
      let loginParam = {}, currentRef = null;
      if(state.activeTab == '1'){//1: 验证码登录
        loginParam = {
          ...state.loginCodeForm,
          loginType: state.activeTab
        };
        currentRef = unref(loginCodeRef);
      } else {//密码登录
        loginParam = {
          ...state.loginForm,
          loginType: state.activeTab
        };
        currentRef = unref(loginRef);
      }
      currentRef.validate((valid) => {
        if (valid) {
          state.loading = true
          store.dispatch("Login", {
            param: loginParam,
            isAgree: props.isAgree
          }).then(() => {
            state.loading = true;
            if(state.activeTab == '1'){
              context.emit('handleCloseDialog', true);
            }else{
              context.emit('handleCloseDialog');
            }

            modal.msgSuccess('登录成功！');
            // this.$router.push({ path: this.redirect || "/xychome" }).catch(()=>{});

            //登录后请求菜单，
            store.dispatch('GetInfo').then(() => {
              isRelogin.show = false
              // store.dispatch('GenerateRoutes').then(accessRoutes => {
              //   // 根据roles权限生成可访问的路由表
              //   router.addRoutes(accessRoutes) // 动态添加可访问路由表
              //   // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
              // })
            }).catch(err => {
                store.dispatch('LogOut').then(() => {
                  Message.error(err)
                })
              })

          }).catch(() => {
            state.loading = false;console.log('oooo8888888888')
          });

        } else {
          console.log('error submit!')
          return false
        }
      })
    }
    const changeProcess = (txt) => {
      context.emit('changeProcess', txt)
    }
    onUnmounted(()=>{
      clearInterval(state.interval);
    })
    return {
        ...toRefs(state),
        handleLogin,
        changeProcess,
        loginRef,
        loginCodeRef,
        getCode
      };
  }
}

</script>


<style scoped lang="scss">
  .login-block{
    .flex{display: flex;}
    flex-direction: column;
    align-items: center;
    padding: 0 22px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    .loginBtn{
      width:100%;height: 43px;font-size: 16px;font-weight: 500;
    }
    .el-tabs{
      width: 100%;
      ::v-deep .el-tabs__item{font-size: 16px;font-weight: bold;}
      // ::v-deep .el-tabs__item:hover,.el-tabs__item.is-active{

      // }
      ::v-deep .el-tabs__nav-wrap::after{background-color: transparent;}
    }
    ::v-deep .el-input__inner{
      height: 43px;
    }
    .el-form-item{margin-bottom: 18px;}
    ::v-deep .el-form-item__label{font-size: 16px;}
    .xyc-register{
      margin: 10px 0 8px 0;
      justify-content: space-between;
      .flex{align-items: center;}
    }
    .blue{
      font-weight: 500;
      color: #1890ff;
      cursor: pointer;
    }
    .get-code{
      font-size: 16px;
      border-left: 1px solid #dedede;
      padding: 0 0 0 10px;
      border-radius: 0;
      width: 92px;
      box-sizing: border-box;
    }
    .get-code.disable{cursor: default;}
    ::v-deep .el-input__suffix{
        display: flex;
      align-items: center;
    }
  }

</style>
