<template>
<div class="xyc-layout">
  <home-head></home-head>
  <div :class="classObj" class="app-wrapper" :style="{'--current-color': theme}">
    <div class="main-container">
      <app-main />

    </div>
    <home-footer></home-footer>
  </div>
</div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import homeHead from "@/views/home/homeHead.vue"
import homeFooter from "@/views/home/homeFooter.vue"
import logoImg from "@/assets/home/logo3.png"
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    homeHead,
    homeFooter,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: state => state.settings.theme,
      sideTheme: state => state.settings.sideTheme,
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: false,//!this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    userLogo(){
      return logoImg;
    },
    variables() {
      return variables;
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";
  $head: 60px;
.xyc-user-center{
  background: #F5F6F9;
  font-family: SourceHanSansSC-Medium, SourceHanSansSC;
  .xyc-head{
    position: fixed;
    top:0;
    left:0;
    right:0;
    color: #333;
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.06);
  }
}
  .app-wrapper {
    padding-top: $mainViewTop;
    @include clearfix;
    position: relative;
    height: 100%;
    min-width: $pageWidth;
    .container-left{
      width: 200px;
      margin-right: 17px;
    }
    .container-right{
      flex:1;
    }
    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$base-sidebar-width});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }

  .sidebarHide .fixed-header {
    width: 100%;
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
