<template>
    <div class="vip-info flex">
        <!-- <el-dropdown> -->
            <div class="vip-btn flex">
              <!-- <time-count class="timeCount"  v-if="isShow" :endTime="endTime"/> -->
              <img :src="imgLevl[userInfo.vipLevel]" style="width: 105px;height: 26px;margin-right: 5px;" alt="">
            </div>
            <!-- <el-dropdown-menu slot="dropdown"
             style="
             border-radius: 13px;
             padding: 0px 0px;
             box-shadow: 0px 0px 4px 0px #AAAAAA;
             border: 1px solid #FFFFFF;
             ">
              <div class="vip-detail-block">
                <div class="title flex">
                  <span>首次注册享受以下功能</span>
                  <img src="../../../assets/home/yh.png" style="margin-left: 8px;width: 147px;height: 32px;" alt="">
                </div>
                <div class="vip-content">
                  <time-count   v-if="isShow" :endTime="endTime"/>
                  <div class="content-top flex">
                    <img src="../../../assets/home/qy.png" style="width: 32px;height: 32px;margin-right: 16px;" alt="">
                    <span>会员权益</span>
                  </div>
                  <div class="item">
                    <div v-for="item in qyData" :key="item.id" class="flex">
                      <img src="../../../assets/home/ok.png" class="xyc-icon-img" alt=""/>
                      <span>{{ item.title }}</span>
                      &nbsp;&nbsp;
                      <span>{{ item.content }}</span>
                    </div>
                  </div>
                </div>
                <div class="more flex">查看更多权益</div>
              </div>
            </el-dropdown-menu> -->
          <!-- </el-dropdown> -->
    </div>
</template>

<script>
  import { computed, ref,toRefs, watch, unref, reactive, nextTick, onMounted } from 'vue';
  import TimeCount from './timeCount.vue';
  import { setCheckNewUser, getUserInfo } from '@/api/search'
  import store from '@/store'
  import { mapState } from 'vuex'
  import vip1 from '@/assets//home/vip1.png'
  import vip3 from '@/assets//home/vip3.png'
export default {
    name: 'level',
    components:{TimeCount},
    setup() {
      const state = reactive({
        qyData:[
            {title: '企业基本信息查询', content: '限免10次', id: 1},
            {title: '企业认证反欺诈', content: '限时免费', id: 2},
            {title: '空壳企业反欺诈', content: '限时免费', id: 3},
            {title: 'AI经营报告', content: '限时前10份低至2折', id: 4},
        ],
        isShow: false,
        endTime: '',
        imgLevl: {
          '1': vip1,
          '3': vip3,
          '4': vip3,
          '10': vip3
        }
      });
      watch(()=>store.state.user.isNewUser,(val)=>{
        if(val == 1){
           getTime();
        }
      })
      const userInfo = computed(()=>{
        return store.state.user;
      })

      const getTime = () => {
        let param = {
          userPhone: store.state.user.phone,
        }
        getUserInfo(param).then(res => {
            state.isShow = true;
            state.endTime = res.data?.memberEndTimeStr;
          }).catch(error => {

          })
      }
     onMounted(()=>{
        if(store.state.user.isNewUser == 1){
           getTime();
        }
     })
     console.log('userInfo', userInfo)
      return {
        ...toRefs(state),
        userInfo,
      };
    }
  }

  </script>


  <style scoped lang="scss">
    .vip-info{
      cursor: pointer;
    }
    .vip-btn{
      position: relative;
      .timeCount{
        right: -79px;
        top: -13px;
      }
    }
    .flex{display: flex;align-items: center;}
    .vip-detail-block{
      border-radius: 13px;

      width: 420px;
      height: 340px;
      background: linear-gradient(180deg, #7FB3FA 0%, #FFFFFF 100%);
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      padding: 22px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title{
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 18px;
      }
      .vip-content{
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 16px 20px;
        position: relative;

        .item > div:not(:last-child){
          margin-bottom: 12px;
        }
        .item div{
          padding-left: 8px;
          img{margin-right: 8px;}
        }
      }
      .content-top{
        font-size: 18px;
        font-weight: 500;
        color: #3D3D3D;
        margin-bottom: 8px;
      }
      .more{
        // width: 304px;
        width: 80%;
        padding: 7px 0;
        background: linear-gradient(135deg, #F1CD8C 0%, #D1A353 100%);
        border-radius: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #3D3D3D;
        margin-top: 22px;
        justify-content: center;
      }
    }

  </style>
