import request from '@/utils/request'

export function getCompanyList(data) {
  return request({
    url: '/xyc/xycTyc/api/getCompanyList',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
export function getCompanyAllList(data) {
  return request({
    url: '/xyc/xycTyc/api/getCompanyListAll',
    method: 'post',
    data: data
  })
}
export function getCompanyBaseInfo(data) {
  return request({
    url: '/xyc/xycTyc/api/baseInfo',
    method: 'post',
    data: data
  })
}
export function setCheckNewUser(data) {
  return request({
    url: '/xyc/xyc/checkNewUser',
    method: 'post',
    data: data
  })
}
export function getUserInfo(data) {
  return request({
    url: '/xyc/xyc/getUserInfo',
    method: 'post',
    data: data
  })
}
//反欺诈
export function getFraudScore(data) {
  return request({
    url: '/xyc/xyc/getFraudScore',
    method: 'post',
    data: data
  })
}

export function getFraudScoreHistory(data) {
  return request({
    url: '/xyc/xyc/corporateFraud/selectInfoByOrderNo',
    method: 'post',
    data: data
  })
}
//空壳反欺诈
export function getEmptyFraudScore(data) {
  return request({
    url: '/xyc/xyc/shellCompanyAntiFraud',
    method: 'post',
    data: data
  })
}
export function getEmptyFraudScoreHistory(data) {
  return request({
    url: '/xyc/xyc/emptyShellFraud/shellFraudByOrderNo',
    method: 'post',
    data: data
  })
}
// 授权接口
export function getWfUrl(data) {
  return request({
    url: '/xyc/xyc/wfq/getUrl',
    method: 'post',
    data: data
  })
}
//授权接口成功与否
export function getMessagesList(data) {
  return request({
    url: '/xyc/xyc/message/messagesList',
    method: 'post',
    data: data
  })
}