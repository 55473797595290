import { login, logout, getInfo, refreshToken } from "@/api/login";
import { getToken, setToken, setExpiresIn, removeToken } from "@/utils/auth";
import { setCheckNewUser, getUserInfo } from "@/api/search";
import modal from "@/plugins/modal";

const user = {
  state: {
    token: getToken(),
    name: "",
    phone: "",
    vipLevel: "",
    userName: "",
    avatar: "",
    roles: [],
    permissions: [],
    isNewUser: "",
    userId: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_PHONE: (state, name) => {
      state.phone = name;
    },
    SET_LEVEL: (state, name) => {
      state.vipLevel = name;
    },
    SET_USER_NAME: (state, name) => {
      state.userName = name;
    },
    SET_USER_ID: (state, name) => {
      state.userId = name;
    },
    IS_NEW_USER: (state, name) => {
      state.isNewUser = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      // const username = userInfo.username.trim()
      // const password = userInfo.password
      // const code = userInfo.code
      // const uuid = userInfo.uuid

      return new Promise((resolve, reject) => {
        if (!userInfo.isAgree) {
          modal.msgError("您还未阅读协议！");
          reject(error);
          return;
        }
        login({ ...userInfo.param })
          .then((res) => {
            let data = res.data;
            setToken(data.xycOAuth2AccessTokenDO?.accessToken);
            commit("SET_TOKEN", data.xycOAuth2AccessTokenDO?.accessToken);
            localStorage.setItem("userInfo",JSON.stringify(data))
            localStorage.setItem("userPhone", data.userPhone);
            // setExpiresIn(data.expires_in)
            // commit('SET_EXPIRES_IN', data.expires_in)
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo({
          userPhone: localStorage.getItem("userPhone"),
        })
          .then((res) => {
            const data = res.data;
            const user = res.user;
            const avatar =
              data.avatarUrl == "" || data.avatarUrl == null
                ? require("@/assets/images/profile.jpg")
                : data.avatarUrl;
            // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            //   commit('SET_ROLES', res.roles)
            //   commit('SET_PERMISSIONS', res.permissions)
            // } else {
            //   commit('SET_ROLES', ['ROLE_DEFAULT'])
            // }
            commit("SET_NAME", data.userName);
            commit("SET_PHONE", data.userPhone);
            commit("SET_AVATAR", data.avatarUrl);
            commit("IS_NEW_USER", data.isNewUser);
            commit("SET_USER_ID", data.id);
            commit("SET_LEVEL", data.vipLevel);
            commit("SET_USER_NAME", data.userCode);
            resolve(data);
          })
          .catch((error) => {
            console.log('c错了');
            reject(error);
          });
      });
    },

    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.token)
          .then((res) => {
            setExpiresIn(res.data);
            commit("SET_EXPIRES_IN", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      console.log('退出系统了');
      return new Promise((resolve, reject) => {
        commit("SET_TOKEN", "");
        removeToken();
        location.href = "/";
        // logout(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   // commit('SET_ROLES', [])
        //   // commit('SET_PERMISSIONS', [])
        //   removeToken()
        //   location.href = '/'
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
