import request from '@/utils/request'
// 注册方法xyc
export function setRegister(data) {
  return request({
    url: '/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 登录方法xyc
export function login(data) {
  return request({
    url: '/xyc/xyc/phonelogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 找回密码xyc
export function resetPw(data) {
  return request({
    url: '/auth/retrieveUserInfo',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 发送手机验证码xyc
export function getPhoneCode(data) {
  return request({
    url: '/xyc/xyc/sendMessage',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 刷新方法
export function refreshToken() {
  return request({
    url: '/auth/refresh',
    method: 'post'
  })
}

// 获取用户详细信息
export function getInfo(data) {
  return request({
    url: '/xyc/xyc/getUserInfo',
    method: 'post',
    data: data
  })
}

// 退出方法xyc
export function logout() {
  return request({
    url: '/xyc/xyc/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// /auth/retrievePassword