<template>
  <header class="xyc-head">
    <div class="head-contanier">
      <div class="flex ai-c">
        <div
          class="flex"
          style="cursor: pointer; margin-right: 40px"
          @click="goHome"
        >
          <img
            :src="defaultLogo ? logoImgMain : logoImgHome"
            style="width: 167px; height: 60px"
            alt=""
          />
        </div>
        <div class="head-search flex ai-c" v-if="isSearch">
          <search-input
            ref="searchRef"
            :userToken="userInfo.token"
            @goLogin="openDialog('login')"
          />
          <el-button
            type="primary"
            style="margin-left: 8px"
            @click="goSearchContent"
            >查一下</el-button
          >
        </div>
      </div>
      <div class="user-content">
        <div class="vip-content">
          <apply :userToken="userInfo.token" @goLogin="openDialog('login')" />
          <div class="unLogin-info" v-if="!userInfo.token">
            <img
              src="../../assets/home/vip.png"
              style="width: 32px; height: 32px"
              alt=""
            />
            <span>开通会员</span>
          </div>
          <div
            class="unLogin-btn"
            v-if="!userInfo.token"
            @click="openDialog('login')"
          >
            <div>登陆</div>
            <div class="hline"></div>
            <div>注册</div>
          </div>
          <level v-if="userInfo.token" />
          <div class="user-info" v-if="userInfo.token">
            <span class="user-name">{{ userInfo.name }}</span>
            <el-dropdown>
              <span class="el-dropdown-link" style="cursor: pointer">
                <img
                  src="../../assets/home/user.png"
                  style="width: 40px; height: 40px; margin-left: 10px"
                  alt=""
                />
              </span>
              <el-dropdown-menu slot="dropdown" style="border-radius: 12px">
                <div class="user-detail-block">
                  <div class="user-info-detail">
                    <img
                      src="../../assets/home/user.png"
                      style="width: 40px; height: 40px"
                      alt=""
                    />
                    <div class="info-detail">
                      <span class="name">{{ userInfo.userName }}</span>
                      <span class="phone">{{ userInfo.phone }}</span>
                    </div>
                  </div>
                  <div class="tool-title">常用工具</div>
                  <div class="user-tool-block">
                    <div
                      v-for="item in toolData"
                      @click="goApply(item)"
                      :key="item.id"
                      class="tool-item"
                    >
                      <img :src="item.icon" alt="" />
                      <span>{{ item.title }}</span>
                    </div>
                  </div>
                  <div class="tool-footer">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                      "
                      @click="goLout"
                    >
                      <img src="../../assets/home/out.png" alt="" />
                      <span style="margin-left: 3px">退出登录</span>
                    </div>
                    <div class="go-center" @click="goCenter">
                      前往个人中心 >
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <dialog-login
      ref="loginDialogRef"
      v-if="dialogVisible"
      @changeModal="changeModal"
    ></dialog-login>

    <!-- <el-dialog   v-if="dialogVisibleAlter"
      custom-class="xyc-dialog-login"
      title=""
      :top="'30%'"
      :visible.sync="dialogVisibleAlter"
      :append-to-body="true"
      :close-on-click-modal="false"
      :width="'384px'">

      <div class="xyc-login-head flex">
        <div style="font-size: 20px;color:#333;margin-bottom: 15px;">安全提示</div>
      </div>
      <div class="flex fd-c ai-c">
        <div>
          当前账号密码为默认密码，为保证账号安全，请尽快前往【系统管理-
          <router-link to="/xyc/center" style="color: #409eff">
              个人中心
              </router-link>】更改密码
        </div>

        <el-button  v-if="totalSeconds"
          size="medium"
          type="info"
          style="width:50%;margin-top: 16px;"
        >
          <span>我已知晓({{ totalSeconds }}s)</span>
        </el-button>
        <el-button v-else
          size="medium"
          type="primary"
          style="width:50%;margin-top: 16px;"
          @click="dialogVisibleAlter = false"
        >
          <span>我已知晓</span>
        </el-button>
       </div>
    </el-dialog> -->
  </header>
</template>

<script>
import {
  computed,
  ref,
  toRefs,
  onMounted,
  unref,
  reactive,
  nextTick,
} from "vue";
import tool1 from "../../assets/home/tool1.png";
import tool2 from "../../assets/home/tool2.png";
import tool3 from "../../assets/home/tool3.png";
import tool4 from "../../assets/home/tool4.png";
import DialogLogin from "./dialogLogin.vue";
import Apply from "./component/apply.vue";
import Level from "./component/level.vue";
import store from "@/store";
import router from "@/router";
import searchInput from "./component/searchInput.vue";
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import logoImgHome from "@/assets/home/logo.png";
import logoImgMain from "@/assets/home/logo3.png";
export default {
  name: "homeHead",
  props: {
    isSearch: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    defaultLogo: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  components: { DialogLogin, Apply, Level, searchInput },
  setup(props) {
    const loginDialogRef = ref(null);
    const searchRef = ref(null);
    const state = reactive({
      isLogin: false,
      toolData: [
        {
          title: "我的服务",
          icon: tool1,
          id: 1,
          url: "/commonTools/myservices",
        },
        {
          title: "下载记录",
          icon: tool2,
          id: 2,
          url: "/commonTools/myservices",
        },
        {
          title: "我的订单",
          icon: tool3,
          id: 3,
          url: "/commonTools/myservices",
        },
        {
          title: "浏览历史",
          icon: tool4,
          id: 4,
          url: "/commonTools/myservices",
        },
      ],
      dialogVisible: false,
      dialogVisibleAlter: false,
      totalSeconds: 0,
      interval: null,
    });
    const goApply = (item) => {
      if (router.history.current.path == item.url) {
        return;
      }
      router.push({
        path: item.url,
      });
    };
    const openDialog = (txt) => {
      state.dialogVisible = true;
      nextTick(() => {
        unref(loginDialogRef).changeProcess(txt);
      });
    };

    const userInfo = computed(() => {
      return store.state.user;
    });
    const userLogo = computed(() => {
      return props.userLogo;
    });
    const goLout = () => {
      store.dispatch("LogOut");
    };
    const goCenter = () => {
      router.push("/xyc/center");
    };
    const goHome = () => {
      router.push("/");
    };
    const getTime = () => {
      state.totalSeconds = 3;
      state.interval = setInterval(function () {
        state.totalSeconds -= 1;
        if (state.totalSeconds <= 0) {
          clearInterval(state.interval);
        }
      }, 1000);
    };
    const goSearchContent = () => {
      nextTick(() => {
        router.push({
          path: "/company/detail",
          query: { companyname: unref(searchRef).companyName },
        });
      });
    };
    const changeModal = (info) => {
      state.dialogVisible = false;
      if (info == true) {
        console.log("验证码llllllll", info);
        nextTick(() => {
          state.dialogVisibleAlter = info;
          getTime();
        });
      }
    };
    return {
      loginDialogRef,
      ...toRefs(state),
      openDialog,
      userInfo,
      userLogo,
      goLout,
      goCenter,
      goHome,
      ...toRefs(props),
      searchRef,
      goSearchContent,
      logoImgHome,
      logoImgMain,
      goApply,
      changeModal,
    };
  },
};
</script>

<style scoped lang="scss">
.xyc-head {
  .vip-content {
    display: flex;
    align-items: center;
  }
  .unLogin-info {
    display: flex;
    align-items: center;
    font-size: 16px;
    // font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #fed500;
  }
  .unLogin-info,
  .vip-info {
    margin: 0 15px;
  }
  .unLogin-btn {
    width: 90px;
    height: 34px;
    background: linear-gradient(135deg, #00a1fc 0%, #006cff 100%);
    border-radius: 6px;
    border: 1px solid #ffffff;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    .hline {
      width: 1px;
      height: 17px;
      background: #ffffff;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    // .user-name{
    //   font-size: 16px;
    //   font-weight: 500;
    //   color: #FFFFFF;
    // }
  }
}

.tool-title {
  font-size: 14px;
  margin: 20px 0 16px 0;
}
.user-detail-block {
  width: 324px;
  // height: 274px;
  background: #ffffff;
  padding: 14px 20px;
  // box-shadow: 0px 0px 4px 0px #AAAAAA;
  font-family: SourceHanSansSC-Medium, SourceHanSansSC;
  font-size: 14px;
  color: #333;
  .user-info-detail {
    display: flex;
    .info-detail {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }
    .name {
      font-size: 16px;
      // font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
    }
    .phone {
      font-size: 12px;
      // font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
    }
  }
  .user-tool-block {
    // font-size: 14px;
    display: flex;
    justify-content: space-around;
  }
  .tool-item {
    display: flex;
    flex-direction: column;
    img {
      width: 44px;
      height: 44px;
      margin-bottom: 10px;
    }
    cursor: pointer;
  }
  .tool-footer {
    margin-top: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    font-weight: 500;
  }
  .go-center {
    color: #006cff;
    cursor: pointer;
  }
}
</style>
