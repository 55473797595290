import Vue from "vue";
import App from "./App.vue";
import Element from 'element-ui'
import Cookies from 'js-cookie'
import store from './store'
import router from "./router";
import './permission' // permission control
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import './assets/icons' // icon


Vue.use(Element, {
    size: Cookies.get('size') || 'medium' // set element-ui default size
  })


new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
